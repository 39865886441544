<template>
    <div class="explorer-section">

        <div class="navigation-container">
            <div class="tabs">
                <router-link class="tab-link" :to="item.url" v-for="item in navItems" :key="item.text">
                    <div class="text-xs">
                        <p>{{ item.text }}</p>
                    </div>
                </router-link>
            </div>

            <div class="search-bar">
                <el-input v-model.trim="searchKey" placeholder="Search">
                    <template #prepend>
                        <el-select v-model="searchType" class="input-prepend" style="width: 110px;" >
                            <el-option v-for="item in searchTypes" :key="item" :label="item" :value="item">
                                {{ item }}
                            </el-option>
                        </el-select>
                    </template>
                    <template #append>
                        <div class="search-button" @click="jumpSearch">
                            <img src="../../assets/images/icons/search.svg" alt="">
                        </div>
                    </template>
                </el-input>
            </div>


        </div>


        <div class="content-container">
            <router-view v-slot="{ Component }">
                <transition :name="animateName">
                    <component :is="Component" />
                </transition>
            </router-view>
        </div>

    </div>
</template>

<script>
import { useRoute, useRouter } from 'vue-router';
import { ref, watchEffect } from 'vue';
import { ElMessage } from 'element-plus';
import { useI18n } from 'vue-i18n';
export default {
    name: 'ExplorerMainSection',
    setup() {
        const { t, locale } = useI18n();
        const route = useRoute();
        const router = useRouter();
        const animateName = ref('zoom-in');
        const navItems = ref([]);
        const searchKey = ref('');
        const searchType = ref('Address');
        const searchTypes = ref(['Address', 'Contract', 'Hash']);

        watchEffect(() => {
            navItems.value = [
                { text: t('explorerPage.overview'), url: '/explorer/overview' },
                { text: t('explorerPage.galaxy'), url: '/explorer/galaxy' },
                { text: t('explorerPage.overallRank'), url: '/explorer/rank' },
                { text: t('explorerPage.consensusConnection'), url: '/explorer/consensusConnection' },
                { text: t('explorerPage.prNode'), url: '/explorer/prNode' },
                { text: t('explorerPage.stakeTransaction'), url: '/explorer/stakeTransaction' },
                { text: t('explorerPage.contractInformation'), url: '/explorer/contractInformation' },
            ];
        });


        const jumpSearch = () => {
            if (!searchKey.value) {
                ElMessage.error('Search key is missing');
            } else {

                router.push({
                    path: '/explorer/search',
                    query: { type: searchType.value.toLowerCase(), key: searchKey.value },
                }).then(() => {
                    console.log('Navigation complete:', route.fullPath);
                }).catch((err) => {
                    console.error('Navigation error:', err);
                });
            }
        };

        return {
            navItems,
            animateName,
            searchKey,
            searchType,
            searchTypes,
            jumpSearch,
        };
    },
};
</script>

<style>
.el-select-dropdown__item.is-selected{
    color: #2EA8AF;
}
.el-input-group__append {
    padding: 0px 5px !important;
}
.el-input__wrapper{
    padding: 1px 0px 1px 5px !important;
}
.el-select__wrapper{
    padding: 1px 5px !important;
}
</style>

<style lang="scss" scoped>
@import '@/assets/style/explorerPage/explorerMainSection.scss';
</style>