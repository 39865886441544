<template>
    <div class="container">
        <div class="connect-title">
            <h4> {{ $t('letsConnect') }}</h4>
        </div>
        <div class="connect-content">
            <div class="content-left">
                <div class="connection-wrapper">

                    <ConnectItem v-for="item in items" :key="item.id" :item="item"  class="connect-item"/>


                 
                </div>
                <!-- <div class="scan-wrap" >
                    <div class="scan-img">
                        <img src="../../assets/images/icons/scan.png" alt="">
                    </div>
                    <div class="scan-text">
                        <p>{{ $t('homePage.scan') }}</p>
                    </div>
                </div> -->
                <div class="subscribe-wrap">
                    <div class="subscribe-text">
                        <p>{{ $t('homePage.subscribeATM') }}</p>
                    </div>
                    <div class="input-wrap">
                        <div class="input">
                            <input v-model="email" type="text" required :placeholder="$t('enterEmail')">
                        </div>
                        <div class="origin-btn" @click="subscribe">
                            <p>{{ $t('subscribe') }}</p>
                        </div>
                    </div>

                </div>

            </div>
            <div class="connect-right">
                <div class="form-text">
                    <p>{{ $t('homePage.fillContactForm') }}</p>
                </div>
                <ContactForm></ContactForm>
            </div>
        </div>
    </div>
</template>
<script>

import { subscribe } from '@/api/api';
import ContactForm from '@/components/common/contactForm.vue';
import ConnectItem from '@/components/common/connectItem.vue';
export default {
    name: 'ContactSection',
    components: {
        ContactForm,
        ConnectItem
    },
    data() {
        return {
            email: '',
            items: [
                { id: 1, imageUrl: "images/homePage/reddit.svg", text: "Reddit", link: 'https://www.reddit.com/r/atmrank/' },
                { id: 2, imageUrl: "images/homePage/twitter.svg", text: "Twitter", link: 'https://twitter.com/ATMrank' },
                { id: 3, imageUrl: "images/homePage/telegram.svg", text: "Telegram", link: 'https://t.me/atm_luca' },
                { id: 4, imageUrl: "images/homePage/discord.svg", text: "Discord", link: 'https://discord.gg/bwAtDM7Mp2' },

            ]
        };
    },
    methods: {
    async subscribe() {
        if (!this.email) {
            alert('Please enter an email address');
            return;
        }

        if (!this.isValidEmail(this.email)) {
            alert('Please enter a valid email address');
            return;
        }

        try {
            const response = await subscribe(this.email);

            console.log(JSON.stringify(response));
            this.email = '';
            alert('Success');
        } catch (error) {
            console.error(error);
            alert('Error');
        }
    },
    isValidEmail(email) {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    }
},


}
</script>