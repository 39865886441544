<template>
    <div class="roadmap-section">
        <div class="roadmap-top">

            <div class="title-buttons-wrapper">
                <div class="section-title">
                    {{ $t('roadmap') }}
                </div>
                <ControlButtons v-show="!mobile" :disableLeft="isListAtStart" :disableRight="isListAtEnd"
                    @switch-element="switchElement" />
            </div>
        </div>

        <div class="roadmap-outer" ref="roadmapContainer">
            <div class="roadmap-container" v-show="!mobile">

                <div v-for="(item, index) in localizedCardDataList" :key="index" class="roadmap-elem">

                    <div class="year" :class="{ 'carrent-year': item.year === '2024' }">{{ item.year }}</div>
                    <div class="quater" :class="{ 'active-quater': item.quater === 'Q2' }">{{ item.quater }}</div>


                    <div class="roadmap-cards">

                        <div class="card" v-for="(card, cardIndex) in item.cards" :key="cardIndex"
                            :class="{ 'active-card': isActiveCard(index, cardIndex), 'visible': !card.visible }">
                            <div class="month">{{ card.month }}</div>
                            <div class="dot-elem"></div>
                            <div class="event">
                                <div class="card-img">
                                    <img lazy="loading" :src="card.imageUrl" alt="Card Image">
                                </div>
                                <div class="text-wrapper">
                                    <div class="content-title">{{ card.title}}</div>
                                    <div class="content-text">{{ card.text }}</div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </div>


        <div class="roadmap-container-mob" v-show="mobile">
            <div v-for="(item, index) in  localizedCardDataList" :key="index" class="roadmap-elem">

                <div class="toggle-wrapper" @click="toggleCards(index)">
                    <div class="year" :class="{ 'carrent-year': item.year === '2024' }">{{
                        item.year }}</div>
                    <div class="quater" :class="{ 'active-quater': item.quater === 'Q2' }">{{ item.quater }}</div>
                </div>

                <transition name="mobile-road">
                    <div class="roadmap-cards" v-show="item.showCards">

                        <div class="roadmap-card" v-for="(card, cardIndex) in item.cards" :key="cardIndex"
                            :class="{ 'active-card': isActiveCard(index, cardIndex), 'visible': !card.visible }">

                            <div class="card">
                                <div class="month">{{ card.month }}</div>
                                <div class="dot-border">
                                    <div class="dot-elem"></div>
                                </div>
                                <div class="event">
                                    <div class="card-img">
                                        <img lazy="loading" :src="card.imageUrl" alt="Card Image">
                                    </div>
                                    <div class="content-title">{{ card.title }}</div>
                                    <div class="content-text">{{ card.text }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </transition>
            </div>
        </div>

    </div>
</template>
<script>

import ControlButtons from './controlButtons.vue';
export default {
    name: 'RoadmapSection',
    components: {
        ControlButtons,
    },

    data() {
        return {
            isListAtStart: false,
            isListAtEnd: false,



            mobile: null,
            windowWidth: null,
            localizedCardDataListData: [],
            roadmapData: [
                {
                    year: '2021',
                    cards: [
                        {
                            month: 'january',
                            title: 'conceptBorn',
                            text: 'conceptBornDesc',
                            imageUrl: 'images/homePage/concept.png',
                            visible: true,
                        },
                        {
                            month: 'october',
                            title: 'smartContracts',
                            text: 'smartContractsDesc',
                            imageUrl: 'images/homePage/contract.png',
                            visible: true,
                        }
                    ]
                },
                {
                    year: '2022',
                    cards: [
                        {
                            month: 'february',
                            title: 'transitionPRNodes',
                            text: 'transitionPRNodesDesc',
                            imageUrl: 'images/homePage/prNodes.png',
                            visible: true,
                        },
                        {
                            month: 'q4',
                            title: 'tokenPR',
                            text: 'tokenPRDesc',
                            imageUrl: 'images/homePage/tokenpr.png',
                            visible: true,
                        }
                    ]
                },
                {
                    year: '2023',
                    cards: [
                        {
                            month: 'july',
                            title: 'avatarLaunch',
                            text: 'avatarLaunchDesc',
                            imageUrl: 'images/homePage/ai.png',
                            visible: true,
                        },
                        {
                            month: 'october',
                            title: 'planComplete',
                            text: 'planCompleteDesc',
                            imageUrl: 'images/homePage/recovery.png',
                            visible: true,
                        }
                    ]
                },

                {
                    year: '2024',
                    quater: 'Q1',
                    cards: [
                        {
                            month: 'recovery',
                            title: 'endRecovery',
                            text: 'endRecoveryDesc',
                            imageUrl: 'images/homePage/endrecovery.png',
                            visible: true,
                        },
                        {
                            month: 'emptyString',
                            title: 'emptyString',
                            text: 'emptyString',
                            imageUrl: 'images/homePage/transparent.png',
                            visible: false,
                        },
                    ]
                },

                {
                    quater: 'Q2',
                    showCards: true,
                    cards: [
                        {
                            month: 'emptyString',
                            title: 'emptyString',
                            text: 'emptyString',
                            imageUrl: 'images/homePage/transparent.png',
                            visible: false,
                        },

                        {
                            month: 'website',
                            title: 'upgradingWebsite',
                            text: 'upgradingWebsiteDesc',
                            imageUrl: 'images/homePage/webredesign.png',
                            visible: true,
                        },

                        {
                            month: 'ios',
                            title: 'iOSAppLive',
                            text: 'iOSAppLiveDesc',
                            imageUrl: 'images/homePage/iosapp.png',
                            visible: true,
                        },
                        {
                            month: 'webApp',
                            title: 'webAppRedesign',
                            text: 'webAppRedesignDesc',
                            imageUrl: 'images/homePage/webapp-redesign.png',
                            visible: true,
                        },
                    ]
                },


                {
                    quater: 'Q3',
                    cards: [
                        {
                            month: 'travel',
                            title: 'travelAgencyCollab',
                            text: 'travelAgencyCollabDesc',
                            imageUrl: 'images/homePage/travelcollab.png',
                            visible: true,
                        },
                        {
                            month: 'agf',
                            title: 'AGFsmartContract',
                            text: 'AGFsmartContractDesc',
                            imageUrl: 'images/homePage/smart-contract.png',
                            visible: true,
                        },
                        {
                            month: 'deflation',
                            title: 'deflation',
                            text: 'deflationDesc',
                            imageUrl: 'images/homePage/deflation.png',
                            visible: true,
                        },
                        {
                            month: 'game',
                            title: 'AGFGameRelease',
                            text: 'AGFGameReleaseDesc',
                            imageUrl: 'images/homePage/gamerelease.png',
                            visible: true,
                        },
                       
                       
                    ]
                },
                {
                    quater: 'Q4',
                    cards: [
                        {
                            month: 'airdrop',
                            title: 'airdropsDevelopment',
                            text: 'airdropsDevelopmentDesc',
                            imageUrl: 'images/homePage/airdropdev.png',
                            visible: true,
                        },
                        {
                            month: 'emptyString',
                            title: 'emptyString',
                            text: 'emptyString',
                            imageUrl: 'images/homePage/transparent.png',
                            visible: false,
                        },
                    ]
                },




            ],

        };
    },

    mounted() {
        //scroll
        this.$nextTick(() => {
            setTimeout(() => {
                this.scrollToElement();
            }, 100);
        });

        // this.$nextTick(() => {
        //     const roadmapContainer = this.$refs.roadmapContainer;

        //     setTimeout(() => {
        //         roadmapContainer.scrollLeft = roadmapContainer.scrollWidth;
        //     }, 500);
        // });

        //change safari stylee
        this.applySafariStyles();
        // toggle cards
        this.localizedCardDataListData = [...this.roadmapData];
        //for disable buttons
        const roadmapContainer = this.$refs.roadmapContainer;
        roadmapContainer.addEventListener('scroll', this.checkListPosition);

    },

    beforeDestroy() {
        const roadmapContainer = this.$refs.roadmapContainer;
        roadmapContainer.addEventListener('scroll', this.checkListPosition);
    },
    
    computed: {
        localizedCardDataList() {
            return this.localizedCardDataListData.map(yearData => ({
                year: yearData.year ? yearData.year : ' ',
                quater: yearData.quater ? yearData.quater : ' ',
                cards: yearData.cards.map(card => ({
                    ...card,
                    month: card.month ? this.$t(`roadMap.${card.month}`) : ' ',
                    title: card.title ? this.$t(`roadMap.${card.title}`) : ' ',
                    text: card.text ? this.$t(`roadMap.${card.text}`) : ' ',
                })),
                showCards: yearData.showCards || false,
            }));
        },

    },
    created() {
        window.addEventListener('resize', this.checkScreen);
        this.checkScreen();
    },
    methods: {
        applySafariStyles() {
            const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

            if (isSafari) {
                this.$nextTick(() => {
                    const cards = this.$refs.roadmapContainer.querySelectorAll('.card');
                    const oddCards = this.$refs.roadmapContainer.querySelectorAll('.card:nth-child(odd)');

                    cards.forEach((card) => {
                        card.style.transform = 'translateY(-18%)';
                    });

                    oddCards.forEach((oddCard) => {
                        oddCard.style.transform = 'translateY(32.5%)';
                    });
                });
            }
        },


        checkScreen() {
            this.windowWidth = window.innerWidth;
            if (this.windowWidth <= 900) {
                this.mobile = true;
            } else {
                this.mobile = false;
            }
        },
        toggleCards(index) {
            if (this.mobile) {
                this.localizedCardDataListData.forEach((item, i) => {
                    if (i !== index) {
                        item.showCards = false;
                    }
                });

                this.localizedCardDataListData[index] = {
                    ...this.localizedCardDataListData[index],
                    showCards: !this.localizedCardDataListData[index].showCards
                };


                this.localizedCardDataListData = [...this.localizedCardDataListData];
            }
        },


        // isLastCard(yearIndex, cardIndex) {

        //     return yearIndex === this.localizedCardDataList.length - 1 && cardIndex === this.localizedCardDataList[yearIndex].cards.length - 1;
        // },

        //change class for a active element (change to (year=>year.year === '2024') if you need to change inside element by year)
        isActiveCard(yearIndex, cardIndex) {
            return yearIndex === this.localizedCardDataList.findIndex(quater => quater.quater === 'Q2') && cardIndex === 1;
        },



        checkListPosition() {
            const roadmapContainer = this.$refs.roadmapContainer;
            this.isListAtStart = roadmapContainer.scrollLeft === 0;
            this.isListAtEnd = roadmapContainer.scrollLeft + roadmapContainer.clientWidth >= roadmapContainer.scrollWidth;
        },



        scrollToElement() {
            const roadmapContainer = this.$refs.roadmapContainer;
            const targetElement = roadmapContainer.querySelector('.carrent-year');

            if (targetElement) {
                roadmapContainer.scrollTo({
                    left: targetElement.offsetLeft,
                    behavior: 'smooth'
                });
            }
        },
        switchElement(direction) {
            if (direction === 'left') {
                this.scrollLeft();
            } else {
                this.scrollRight();
            }
        },


        scrollLeft() {
            const roadmapContainer = this.$refs.roadmapContainer;
            roadmapContainer.scrollBy({
                left: -1000,
                behavior: 'smooth'
            });
        },

        scrollRight() {
            const roadmapContainer = this.$refs.roadmapContainer;
            roadmapContainer.scrollBy({
                left: 1000,
                behavior: 'smooth'
            });
        }

    }
}

</script>